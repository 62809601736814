
.toolbar-overlay {
	align-items: flex-end;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: var(--dialog-z-index);

	&.toolbar-open {
		pointer-events: initial;

		&.toolbar-modal {
			background-color: rgba(0, 0, 0, 0.5);
		}

		.toolbar-component {
			transform: translate3d(0, 0, 0);
		}
	}

	.toolbar-component {
		--toolbar-component-background-color: var(--background-tertiary);
		--toolbar-component-handler-height: 4px;
		--toolbar-component-handler-margin-bottom: 24px;
		--toolbar-component-max-height: 80vh;
		--toolbar-component-padding-bottom: 24px;
		--toolbar-component-padding-top: 16px;
		--toolbar-component-padding-x: 20px;
		--toolbar-component-title: 0px;
		--toolbar-component-title-margin-bottom: 16px;
		--toolbar-component-title-padding-x: 0px;
		background-color: var(--toolbar-component-background-color);
		border-radius: 20px 20px 0px 0px;
		max-height: var(--toolbar-component-max-height);
		overscroll-behavior: contain;
		padding: var(--toolbar-component-padding-top) var(--toolbar-component-padding-x) var(--toolbar-component-padding-bottom);
		position: absolute;
		transition: transform 0.3s ease-out;
		transform: translate3d(0, 100%, 0);
		user-select: none;
		width: 100%;

		.toolbar-handler {
			background-color: var(--background-primary-inverse);
			border-radius: 20px;
			height: var(--toolbar-component-handler-height);
			margin: 0px auto;
			margin-bottom: var(--toolbar-component-handler-margin-bottom);
			width: 60px;
		}

		.toolbar-body {
			--toolbar-component-body-max-height: calc(var(--toolbar-component-max-height) - (var(--toolbar-component-handler-height) + var(--toolbar-component-handler-margin-bottom) + var(--toolbar-component-padding-top) + var(--toolbar-component-padding-bottom) + var(--toolbar-component-title)));
			background-color: var(--toolbar-component-background-color);
			max-height: var(--toolbar-component-body-max-height);
			overflow-y: auto;

			&.toolbar-body-with-title {
				--toolbar-component-title: calc(19px + var(--toolbar-component-title-margin-bottom));
			}
		}

		.toolbar-title {
			font-family: var(--font-family-bold);
			font-size: var(--font-size-s);
			font-weight: var(--font-weight-bold);
			margin-bottom: var(--toolbar-component-title-margin-bottom);
			padding: 0px var(--toolbar-component-title-padding-x);
		}
	}
}
